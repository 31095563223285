import { createStore } from "redux";

const initialState = {
  email: "",
  birthday: "",
  phone: "",
  langType: "",
  lastName: "",
  isBiometric: false,
  firstName: "",
  handed: "",
  profileImg: "",
  stripeCustomerID: "",
  stripeSubscriptionID: "",
  stripeSubscriptionStatus: "",
  stripeSubscriptionResponse: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_USER_INFO":
      return {
        ...state,
        ...action.payload,
      };
    case "REMOVE_USER_INFO":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const store = createStore(reducer);

export default store;
