import { Stack, IconButton, Drawer, Divider, Avatar } from "@mui/material";
import { useEffect, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { ChevronLeft } from "@mui/icons-material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import AccountSetting from "./AccountSetting";
import ManageSubscriptions from "./ManageSubscriptions";

import { firebaseAuth } from "../utils/firebase";
import DeleteAccountDialog from "../components/accountSettings/DeleteAccountDialog";
import { environment } from "../utils/configs";
import CancelSubscriptionDialog from "../components/manageSubscriptions/CancelSubscriptionDialog";
import ResumeSubscriptionDialog from "../components/manageSubscriptions/ResumeSubscriptionDialog";

const TabLevel1 = (props) => {
  return (
    <Stack
      onClick={props.onClick}
      sx={{
        fontSize: "20px",
        fontWeight: props.active ? "bold" : "normal",
        marginTop: props.marginTop ? props.marginTop : 0,
        ":hover": {
          color: "#666",
        },
        ":active": {
          color: "#000",
        },
        transition: "color 200ms ease",
      }}
    >
      <Link to={props.href}>{props.children}</Link>
    </Stack>
  );
};

const TabLevel2 = (props) => {
  return (
    <Stack
      onClick={props.onClick}
      sx={{
        cursor: "pointer",
        fontSize: "16px",
        marginLeft: "14px",
        marginTop: "10px",
        ":hover": {
          color: "#444",
        },
        ":active": {
          color: "#000",
        },
        transition: "color 200ms ease",
      }}
    >
      {props.children}
    </Stack>
  );
};

const Dashboard = () => {
  const location = useLocation();
  let navigate = useNavigate();
  const pathId = location.pathname === "/billing/subscribe" ? 1 : 0;
  const [tabIndex, setTabIndex] = useState(pathId); //0: Account Settins, 1: Manage Subscription

  useEffect(() => {
    setTabIndex(pathId);
  }, [pathId]);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const userInfo = useSelector((state) => state);
  const onSignOutHandle = () => {
    firebaseAuth
      .signOut()
      .then(() => {
        navigate("/login");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const [deleteAccountModalOpen, setDeleteAccountModalOpen] = useState(false);

  const handleDeleteAccountModalOpen = () => {
    setDeleteAccountModalOpen(true);
  };
  const handleDeleteAccountModalClose = () => {
    setDeleteAccountModalOpen(false);
  };

  const [cancelSubscriptionModalOpen, setCancelSubscriptionModalOpen] =
    useState(false);

  const handleCancelSubscriptionModalOpen = () => {
    setCancelSubscriptionModalOpen(true);
  };
  const handleCancelSubscriptionModalClose = () => {
    setCancelSubscriptionModalOpen(false);
  };
  const [resumeSubscriptionModalOpen, setResumeSubscriptionModalOpen] =
    useState(false);

  const handleResumeSubscriptionModalOpen = () => {
    setResumeSubscriptionModalOpen(true);
  };
  const handleResumeSubscriptionModalClose = () => {
    setResumeSubscriptionModalOpen(false);
  };

  const _menuItems = () => {
    return (
      <>
        <TabLevel1 active={tabIndex === 0} href="/billing/account">
          Account settings
        </TabLevel1>
        <TabLevel2>change password</TabLevel2>
        <TabLevel2>app settings</TabLevel2>
        <TabLevel1
          active={tabIndex === 1}
          href="/billing/subscribe"
          marginTop="34px"
        >
          Manage subscription
        </TabLevel1>
        <TabLevel2>change plans</TabLevel2>
        {userInfo?.stripeSubscriptionResponse?.status === "active" &&
        userInfo?.stripeSubscriptionResponse?.cancel_at_period_end === true ? (
          <TabLevel2 onClick={handleResumeSubscriptionModalOpen}>
            resume subscription
          </TabLevel2>
        ) : (
          <TabLevel2 onClick={handleCancelSubscriptionModalOpen}>
            cancel subscription
          </TabLevel2>
        )}
        <TabLevel1 marginTop="42px" onClick={handleDeleteAccountModalOpen}>
          Delete account
        </TabLevel1>
        <TabLevel1 marginTop="42px" active onClick={onSignOutHandle}>
          Sign out
        </TabLevel1>
      </>
    );
  };
  return (
    <Stack
      sx={{
        width: "100%",
        height: "100vh",
      }}
    >
      <Stack
        sx={{
          width: "100%",
          height: "52px",
          backgroundColor: "#F8F7F7",
          justifyContent: "space-between",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Stack
          sx={{
            fontSize: "22px",
            fontWeight: "bold",
            marginLeft: "18px",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{
              display: { lg: "none" },
              mr: 1,
            }}
            onClick={() => {
              setDrawerOpen(true);
            }}
          >
            <MenuIcon />
          </IconButton>
          <Link to="https://prtnr.app">
            <Stack sx={{ flexDirection: "row", userSelect: "none" }}>
              pr
              <Stack style={{ color: "red", marginLeft: "-0.5px" }}>t</Stack>
              nr
            </Stack>
          </Link>
        </Stack>
        <Stack
          sx={{
            fontSize: "16px",
            fontWeight: "600",
            marginRight: "18px",
            flexDirection: "row",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <Avatar
            alt="Remy Sharp"
            src={userInfo.profileImg}
            sx={{
              height: 28,
              width: 28,
              backgroundColor: "#ccc",
            }}
          />
          Me
        </Stack>
        <Stack display={"none"}>{environment}</Stack>
      </Stack>
      <Stack
        sx={{
          flexDirection: "row",
          width: "100%",
          flex: 1,
          marginTop: "12px",
          marginBottom: "25px",
          overflow: "hidden",
        }}
      >
        <Drawer
          anchor="left"
          open={drawerOpen}
          onClose={() => {
            setDrawerOpen(false);
          }}
          sx={{
            display: { lg: "none" },
          }}
          onClick={() => setDrawerOpen(false)}
        >
          <Stack
            sx={{
              height: 51,
              alignItems: "flex-end",
              justifyContent: "center",
              pr: 1,
            }}
          >
            <IconButton
              sx={{
                height: 40,
                width: 40,
              }}
            >
              <ChevronLeft />
            </IconButton>
          </Stack>
          <Divider />
          <Stack
            sx={{
              marginX: "18px",
              marginY: "12px",
              width: 300,
            }}
          >
            {_menuItems()}
          </Stack>
        </Drawer>
        <Stack
          sx={{
            width: 300,
            borderRight: "1px solid #707070",
            backgroundColor: "white",
            display: { xs: "none", lg: "flex" },
          }}
        >
          <Stack
            sx={{
              marginX: "18px",
              marginY: "12px",
            }}
          >
            {_menuItems()}
          </Stack>
        </Stack>
        <DeleteAccountDialog
          modalOpen={deleteAccountModalOpen}
          handleModalClose={handleDeleteAccountModalClose}
        />
        <CancelSubscriptionDialog
          modalOpen={cancelSubscriptionModalOpen}
          handleModalClose={handleCancelSubscriptionModalClose}
        />
        <ResumeSubscriptionDialog
          modalOpen={resumeSubscriptionModalOpen}
          handleModalClose={handleResumeSubscriptionModalClose}
        />
        <Stack
          sx={{
            flex: 1,
            paddingX: { xs: "15px", sm: "33px" },
            alignItems: "center",
            overflow: "auto",
          }}
        >
          <Stack
            sx={{
              width: "100%",
              maxWidth: 990,
            }}
          >
            {tabIndex === 0 && <AccountSetting />}
            {tabIndex === 1 && <ManageSubscriptions />}
          </Stack>
        </Stack>
      </Stack>

      {/*<Stack sx={{
                    width: "100%",
                    height: "52px",
                    backgroundColor: "#F8F7F7",
                }}>
            </Stack>*/}
    </Stack>
  );
};

export default Dashboard;
