import { useEffect, useState } from "react";
import {
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Stack,
} from "@mui/material";
import CancelButton from "../buttons/CancelButton";
import ConfirmButton from "../buttons/ConfirmButton";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Backend_Stripe_API, environment } from "../../utils/configs";
import { updateUserInfo } from "../../utils/actions";
import {
  collection,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { firebaseFirestore } from "../../utils/firebase";
import { useNavigate } from "react-router-dom";

const CancelSubscriptionDialog = ({ modalOpen, handleModalClose }) => {
  const [processing, setProcessing] = useState(false);

  const [checked, setChecked] = useState(false);

  const userInfo = useSelector((state) => state);
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  useEffect(() => {
    setChecked(false);
  }, [modalOpen]);

  const handleConfirm = async (event) => {
    event.preventDefault();
    setProcessing(true);
    try {
      const response = await axios.post(
        `${Backend_Stripe_API}/subscriptions/cancel_at_period_end`,
        {
          environment: environment,
          subscriptionId: userInfo.stripeSubscriptionID,
        }
      );
      dispatch(
        updateUserInfo({
          stripeSubscriptionStatus: response.data?.status,
          stripeSubscriptionResponse: response.data,
        })
      );
      const registrationRef = collection(firebaseFirestore, "Registration");
      const q = query(
        registrationRef,
        where("email", "==", userInfo.email.toLowerCase())
      );
      const querySnapshot = await getDocs(q);
      if (querySnapshot.size > 0) {
        const userInfo = querySnapshot.docs[0];
        await updateDoc(userInfo.ref, {
          stripeSubscriptionStatus: response.data?.status,
          stripeSubscriptionResponse: response.data,
        });
        navigate("/billing/account");

        toast.success(
          "Your subscription will be canceled at the end of period. You can resume it."
        );
        handleModalClose();
      }
    } catch (error) {
      console.error(error);
      error?.message && toast.error(error?.message);
      error?.response?.data?.raw?.message &&
        toast.info(error?.response?.data?.raw?.message);
    }
    setProcessing(false);
  };

  return (
    <Dialog
      open={modalOpen}
      onClose={handleModalClose}
      sx={{
        "& .MuiPaper-root": {
          borderRadius: 0,
          margin: 0,
          maxHeight: "100%",
          transition: "width 500ms ease",
        },
      }}
    >
      <DialogTitle sx={{ fontFamily: "Akrobat", fontWeight: "bold" }}>
        Cancel Subscription
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ fontFamily: "Akrobat" }}>
          To cancel subscription, please press Confirm button. Your subscription
          will be canceled at the end of period.
        </DialogContentText>
        <Stack
          sx={{
            marginTop: "10px",
            gap: 2,
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={handleChange}
                sx={{
                  color: "#999",
                  "&.Mui-checked": {
                    color: "#0A284A",
                  },
                }}
              />
            }
            label="Agree to canceling subscription"
            sx={{
              userSelect: "none",
              color: "#0A284A",
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={handleModalClose} />
        <ConfirmButton onClick={handleConfirm} disabled={!checked}>
          {processing ? (
            <CircularProgress sx={{ color: "#ccc" }} thickness={6} size={22} />
          ) : (
            "Confirm"
          )}
        </ConfirmButton>
      </DialogActions>
    </Dialog>
  );
};

export default CancelSubscriptionDialog;
