const CheckSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23">
      <g fill="#000">
        <path
          d="M15 3 L3 3 L3 20 L20 20 L20 11"
          stroke="black"
          fill="none"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 11 L11 14 L21 4"
          stroke="black"
          fill="none"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default CheckSvg;
