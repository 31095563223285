import { useEffect,  useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Input,
  Stack,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import {  toast } from "react-toastify";

import {
  confirmPasswordReset,
  verifyPasswordResetCode,
} from "firebase/auth";

import {
  firebaseAuth,
} from "../utils/firebase";

const PasswordResetPage = () => {
  const location = useLocation();
  let navigate = useNavigate();
  const [splashImageId, setSplashImageId] = useState(null);

  useEffect(() => {
    const actionCode = new URLSearchParams(location.search).get("oobCode");
    // Verify the password reset code is valid
    verifyPasswordResetCode(firebaseAuth, actionCode)
      .then((email) => {
        toast.info("Code is valid, can proceed with password reset");
      })
      .catch((error) => {
        toast.error("Invalid or expired code");
      });
  }, [location.search]);

  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handlePasswordReset = async() => {
    const actionCode = new URLSearchParams(location.search).get("oobCode");
    await confirmPasswordReset(firebaseAuth, actionCode, password)
      .then(() => {
        toast.success("Password reset successful");
        navigate("/login");
      })
      .catch((error) => {
        console.log(error.code);
        switch (error.code) {
          case "auth/weak-password":
            toast.error("Password is weak.");
            break;
          case "auth/invalid-action-code":
            toast.error("Action code is invalid.");
            navigate("/login");
            break;
          default:
            toast.error("Firebase Error!");
        }
      });    
  };
  
  useEffect(() => {
    console.log("res");
    setSplashImageId(Math.floor(Math.random() * 7 + 1));
  }, []);
  
  const handlePasswordKeyDown = (event) => {
    if (event.key === "Enter") handlePasswordReset();
  };

  return (
    <Stack
      sx={{
        backgroundImage: splashImageId
          ? `url("/assets/images/splashimage/s${splashImageId}.png")`
          : null,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "100vh",
        width: "100vw",
      }}
    >
      <Stack
        sx={{
          position: "absolute",
          height: "100%",
          width: "100%",
          backdropFilter: "blur(4px) brightness(0.3)",
          color: "white",
          alignItems: "center",
          justifyContent: "center",
          gap: 2,
        }}
      >
        <Stack
          sx={{
            flexDirection: "row",
          }}
        >
          <Stack
            sx={{
              fontSize: 22,
              fontWeight: "bold",
            }}
          >
            prtnr.&nbsp;
          </Stack>
          <Stack
            sx={{
              fontSize: 16,
              paddingTop: "6px",
            }}
          >
            be btr
          </Stack>
        </Stack>
        <Stack
          sx={{
            height: 43,
            width: 323,
            background: "white",
            borderRadius: 30,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Input
            disableUnderline
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setPasswordVisible((prev) => !prev)}
                  edge="end"
                >
                  {passwordVisible ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            sx={{
              height: 25,
              width: 300,
              fontFamily: "Akrobat",
              fontWeight: "bold",
              fontSize: 20,
              color: "#0A284A",
              "& .MuiInputBase-input": {
                padding: 0,
              },
            }}
            type={passwordVisible ? "text" : "password"}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={handlePasswordKeyDown}
          />
        </Stack>
        <Button
          variant="contained"
          disableElevation
          onClick={handlePasswordReset}
          sx={{
            height: 43,
            width: 323,
            borderRadius: 20,
            fontFamily: "Akrobat",
            fontWeight: "bold",
            backgroundColor: "black",
            ":hover": {
              backgroundColor: "#111",
            },
            ":disabled": {
              backgroundColor: "#111B",
              color: "#fffb",
            },
            fontSize: 20,
            textTransform: "none",
          }}
        >
          Reset Password
        </Button>
      </Stack>
    </Stack>
  );
};

export default PasswordResetPage;
