import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  collection,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from "@mui/material";
import CancelButton from "../buttons/CancelButton";
import ConfirmButton from "../buttons/ConfirmButton";
import Avatar from "../avatarPicker/avatar.jsx";

import { firebaseFirestore, firebaseStorage } from "../../utils/firebase";

import { updateUserInfo } from "../../utils/actions.js";
import {
  getDownloadURL,
  ref,
  uploadBytes,
  uploadBytesResumable,
} from "firebase/storage";
import { toast } from "react-toastify";

const ChangeAvatarDialog = ({ modalOpen, handleModalClose }) => {
  const [processing, setProcessing] = useState(false);
  const userInfo = useSelector((state) => state);
  const [defaultPreview, setDefaultPreview] = useState(null);
  const dispatch = useDispatch();
  const onCropDefault = (preview) => {
    setDefaultPreview(preview);
  };

  const onCloseDefault = () => {
    setDefaultPreview(null);
  };

  const base64toBlob = (base64Data, contentType) => {
    contentType = contentType || "";
    var sliceSize = 1024;
    var byteCharacters = atob(base64Data);
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      var begin = sliceIndex * sliceSize;
      var end = Math.min(begin + sliceSize, bytesLength);

      var bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  };
  const handleConfirm = async (event) => {
    event.preventDefault();
    setProcessing(true);

    try {
      const registrationRef = collection(firebaseFirestore, "Registration");
      const q = query(registrationRef, where("email", "==", userInfo.email));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.size > 0) {
        const userInfo = querySnapshot.docs[0];
        const metadata = "image/png";
        const blob = base64toBlob(
          defaultPreview.substring(defaultPreview.indexOf(",") + 1),
          metadata
        );
        const name = new Date().getTime() + "-media.png";
        const storageRef = ref(firebaseStorage, `Registration/${name}`);
        const uploadTask = uploadBytesResumable(storageRef, blob, metadata);
        await uploadBytes(storageRef, blob).then((snapshot) => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            updateDoc(userInfo.ref, { profileImg: url });
            dispatch(updateUserInfo({ profileImg: url }));
            toast.success("Your profile avatar has been updated successfully.");
          });
        });
      }
    } catch (error) {
      console.log(error);
      toast.error("Firebase Error!");
    }
    setProcessing(false);
    handleModalClose();
  };
  return (
    <Dialog
      open={modalOpen}
      onClose={handleModalClose}
      sx={{
        "& .MuiPaper-root": {
          borderRadius: 0,
          margin: 0,
          maxHeight: "100%",
          transition: "width 500ms ease",
        },
      }}
    >
      <DialogTitle sx={{ fontFamily: "Akrobat", fontWeight: "bold" }}>
        Setting Avatar
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ fontFamily: "Akrobat" }}>
          To set up account avatar, please select image file here.
        </DialogContentText>
        <Stack
          sx={{
            marginTop: "10px",
            gap: 2,
          }}
        >
          <Stack
            sx={{
              overflow: "auto",
            }}
          >
            <Avatar
              //width={390}
              height={335}
              //exportSize={390}
              onCrop={onCropDefault}
              onClose={onCloseDefault}
              exportAsSquare={true}
            />
          </Stack>
          {defaultPreview && (
            <Stack
              flexDirection="row"
              alignItems="center"
              gap={3}
              justifyContent="center"
            >
              <Stack
                sx={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                Preview :
              </Stack>
              <img
                alt=""
                style={{
                  width: "150px",
                  height: "150px",
                  borderRadius: "100%",
                }}
                src={defaultPreview}
              />
            </Stack>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={handleModalClose} />
        <ConfirmButton onClick={handleConfirm} disabled={!defaultPreview}>
          {processing ? (
            <CircularProgress sx={{ color: "#ccc" }} thickness={6} size={22} />
          ) : (
            "Confirm"
          )}
        </ConfirmButton>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeAvatarDialog;
