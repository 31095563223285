import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  Stack,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  CircularProgress,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import CancelButton from "../buttons/CancelButton";
import ConfirmButton from "../buttons/ConfirmButton";

import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { updateUserInfo } from "../../utils/actions";
import {
  collection,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { firebaseFirestore } from "../../utils/firebase";
import { toast } from "react-toastify";

const convertDateFormat = (date) => {
  try {
    const formattedDate = new Date(date).toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
    return formattedDate;
  } catch (error) {
    return "";
  }
};

const ChangeDetailsDialog = ({ modalOpen, handleModalClose }) => {
  const userInfo = useSelector((state) => state);
  const [processing, setProcessing] = useState(false);
  const dispatch = useDispatch();

  const [state, setState] = useState({
    birthday: userInfo.birthday,
    firstName: userInfo.firstName,
    lastName: userInfo.lastName,
    phone: userInfo.phone,
  });
  const [error, setError] = useState({
    birthday: false,
    firstName: false,
    lastName: false,
    phone: false,
  });
  const [helperText, setHelperText] = useState({
    birthday: "",
    firstName: "",
    lastName: "",
    phone: "",
  });

  useEffect(() => {
    setState({
      birthday: userInfo.birthday,
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      phone: userInfo.phone,
    });
  }, [userInfo, modalOpen]);

  const handleChange = (e) => {
    if (e.target.name === "firstName" || e.target.name === "lastName") {
      if (!e.target.value) {
        setError((prev) => ({ ...prev, [e.target.name]: true }));
        setHelperText((prev) => ({
          ...prev,
          [e.target.name]: "This field is required.",
        }));
      } else {
        setError((prev) => ({ ...prev, [e.target.name]: false }));
        setHelperText((prev) => ({ ...prev, [e.target.name]: "" }));
      }
    }
    setState((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const handleConfirm = async (event) => {
    event.preventDefault();
    setProcessing(true);
    const updatedData = {
      ...state,
      birthday: convertDateFormat(state.birthday),
    };
    dispatch(updateUserInfo(updatedData));
    try {
      const registrationRef = collection(firebaseFirestore, "Registration");

      const q = query(registrationRef, where("email", "==", userInfo.email));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.size > 0) {
        const userInfo = querySnapshot.docs[0];
        updateDoc(userInfo.ref, updatedData);
        toast.success("Your profile has been updated successfully.");
      }
    } catch (error) {
      console.log(error);
      toast.error("Firebase Error!");
    }
    setProcessing(false);
    handleModalClose();
  };
  return (
    <Dialog
      open={modalOpen}
      onClose={handleModalClose}
      sx={{
        "& .MuiPaper-root": {
          borderRadius: 0,
          margin: 0,
          maxHeight: "100%",
        },
      }}
    >
      <DialogTitle sx={{ fontFamily: "Akrobat", fontWeight: "bold" }}>
        Setting Account Details
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ fontFamily: "Akrobat" }}>
          To set up account details, please enter your name, date of birth, etc.
        </DialogContentText>
        <Stack
          sx={{
            marginTop: "10px",
            gap: 2,
          }}
        >
          <Stack
            sx={{
              gap: 2,
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <TextField
              id="firstName"
              name="firstName"
              label="First Name"
              variant="outlined"
              value={state.firstName}
              size="small"
              onChange={handleChange}
              error={error.firstName}
              helperText={helperText.firstName}
              focused={error.firstName ? true : undefined}
            />
            <TextField
              id="lastName"
              name="lastName"
              label="Last Name"
              variant="outlined"
              size="small"
              value={state.lastName}
              onChange={handleChange}
              error={error.lastName}
              helperText={helperText.lastName}
              focused={error.lastName ? true : undefined}
            />
          </Stack>
          <DatePicker
            defaultValue={dayjs(new Date(userInfo.birthday).getTime())}
            label="Birthday"
            desktopModeMediaQuery="@media (pointer: fine)"
            onError={(e) => {
              setError((prev) => ({ ...prev, birthday: e ? true : false }));
            }}
            onChange={(e) => {
              setState((prev) => ({ ...prev, birthday: e?.$d }));
            }}
          />
          {helperText.birthday}
          <MuiTelInput
            label="Phone Number"
            defaultCountry="US"
            value={state.phone}
            error={error.phone}
            helperText={helperText.phone}
            onChange={(val) => {
              setState((prev) => ({ ...prev, phone: val }));
              setError((prev) => ({ ...prev, phone: !matchIsValidTel(val) }));
              setHelperText((prev) => ({
                ...prev,
                phone: matchIsValidTel(val)
                  ? ""
                  : "This phone number is invalid.",
              }));
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={handleModalClose} />
        <ConfirmButton
          onClick={handleConfirm}
          disabled={
            !Object.values(error).every((value) => value === false) ||
            !Object.values(state).every((value) => value)
          }
        >
          {processing ? (
            <CircularProgress sx={{ color: "#ccc" }} thickness={6} size={22} />
          ) : (
            "Confirm"
          )}
        </ConfirmButton>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeDetailsDialog;
