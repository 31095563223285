import { useEffect, useState } from "react";
import {
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Stack,
} from "@mui/material";
import { deleteUser } from "firebase/auth";
import { firebaseAuth } from "../../utils/firebase";
import CancelButton from "../buttons/CancelButton";
import ConfirmButton from "../buttons/ConfirmButton";
import { toast } from "react-toastify";

const DeleteAccountDialog = ({ modalOpen, handleModalClose }) => {
  const [processing, setProcessing] = useState(false);

  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleConfirm = async (event) => {
    event.preventDefault();
    setProcessing(true);
    await deleteUser(firebaseAuth.currentUser)
      .then(() => {
        toast.success("Your account has been deleted.");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Firebase Error!");
      });
    setProcessing(false);
  };

  useEffect(() => {
    setChecked(false);
  }, [modalOpen]);

  return (
    <Dialog
      open={modalOpen}
      onClose={handleModalClose}
      sx={{
        "& .MuiPaper-root": {
          borderRadius: 0,
          margin: 0,
          maxHeight: "100%",
          transition: "width 500ms ease",
        },
      }}
    >
      <DialogTitle sx={{ fontFamily: "Akrobat", fontWeight: "bold" }}>
        Deleting Account
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ fontFamily: "Akrobat" }}>
          To delete account, please press Confirm button.
        </DialogContentText>
        <Stack
          sx={{
            marginTop: "10px",
            gap: 2,
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={handleChange}
                sx={{
                  color: "#999",
                  "&.Mui-checked": {
                    color: "#0A284A",
                  },
                }}
              />
            }
            label="Agree to deleting account"
            sx={{
              userSelect: "none",
              color: "#0A284A",
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={handleModalClose} />
        <ConfirmButton onClick={handleConfirm} disabled={!checked}>
          {processing ? (
            <CircularProgress sx={{ color: "#ccc" }} thickness={6} size={22} />
          ) : (
            "Confirm"
          )}
        </ConfirmButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteAccountDialog;
