import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, Stack, Avatar, Grid } from "@mui/material";
import ChangePassword from "../components/accountSettings/ChangePassword";
import ChangeDetailsDialog from "../components/accountSettings/ChangeDetailsDialog";
import ChangeAvatarDialog from "../components/accountSettings/ChangeAvatarDialog";

const convertDateFormat = (date) => {
  try {
    const formattedDate = new Date(date).toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
    return formattedDate;
  } catch (error) {
    return "";
  }
};

const AccountSetting = () => {
  let navigate = useNavigate();
  const userInfo = useSelector((state) => state);
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const [avatarModalOpen, setAvatarModalOpen] = useState(false);

  const handleSettingsModalOpen = () => {
    setSettingsModalOpen(true);
  };
  const handleSettingsModalClose = () => {
    setSettingsModalOpen(false);
  };
  const handleAvatarModalOpen = () => {
    setAvatarModalOpen(true);
  };
  const handleAvatarModalClose = () => {
    setAvatarModalOpen(false);
  };
  return (
    <>
      <Stack
        sx={{
          border: "1px solid #70707080",
          height: {
            sm: 160,
          },
          backgroundColor: "#F6F5F5",
          fontSize: {
            xs: "20px",
            sm: "35px",
          },
          padding: { xs: 2, sm: "inherit" },
          fontWeight: "bold",
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
          overflowWrap: "break-word",
          wordWrap: "break-word",
        }}
      >
        Subscribe now to lock in long term perks and new features.
        <br />
        Subscribe now and save til 2025.
      </Stack>
      <Stack
        sx={{
          fontSize: "18px",
          fontWeight: "bold",
          marginTop: "21.5px",
          width: "100%",
          textAlign: "left",
          marginBottom: "16.5px",
        }}
      >
        {`Welcome Back, ${userInfo.firstName ? userInfo.firstName : ""}`}
      </Stack>
      <Stack
        sx={{
          backgroundColor: "#FAFAFA",
          width: "100%",
          justifyContent: "space-between",
          flexDirection: {
            xs: "column",
            md: "row",
          },
          alignItems: "center",
        }}
      >
        <Stack
          sx={{
            flex: 1,
            width: "100%",
          }}
        >
          <Stack
            sx={{
              fontSize: "18px",
              fontWeight: "bold",
              marginTop: "7px",
              marginLeft: "10px",
            }}
          >
            Account Settings
          </Stack>
          <Stack
            sx={{
              height: "100%",
              marginTop: "10px",
              marginBottom: "28px",
              justifyContent: "space-around",
              gap: 4,
              paddingX: "25px",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: "center",
            }}
          >
            <Stack
              sx={{
                alignItems: "center",
                gap: "10px",
                justifyContent: "center",
                flexDirection: { xs: "row", sm: "column" },
                flexWrap: "wrap",
              }}
            >
              <Avatar
                onClick={handleAvatarModalOpen}
                src={userInfo.profileImg}
                sx={{
                  height: 148,
                  width: 148,
                  borderRadius: 80,
                  backgroundColor: "black",
                  cursor: "pointer",
                  ":hover": {
                    opacity: 0.9,
                  },
                  ":active": {
                    opacity: 0.5,
                  },
                  transition: "all 0.3s ease",
                }}
              ></Avatar>
              <Button
                onClick={handleAvatarModalOpen}
                sx={{
                  color: "black",
                  fontSize: "16px",
                  fontFamily: "Akrobat",
                  textTransform: "none",
                  borderRadius: 0,
                }}
              >
                Change Photo
              </Button>

              <ChangeAvatarDialog
                modalOpen={avatarModalOpen}
                handleModalClose={handleAvatarModalClose}
              />
            </Stack>
            <Stack
              sx={{
                width: { xs: "100%", sm: 320 },
                justifyContent: "space-between",
              }}
            >
              Account Details
              <Stack
                sx={{
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
              >
                <Stack
                  sx={{
                    minHeight: 34,
                    borderBottom: "1px solid #707070",
                    justifyContent: "flex-end",
                  }}
                >
                  <Grid container>
                    <Grid item xs={6}>
                      Birthday :
                    </Grid>
                    <Grid item xs={6}>
                      {userInfo.birthday
                        ? convertDateFormat(userInfo.birthday)
                        : ""}
                    </Grid>
                  </Grid>
                </Stack>
                <Stack
                  sx={{
                    minHeight: 34,
                    borderBottom: "1px solid #707070",
                    justifyContent: "flex-end",
                  }}
                >
                  <Grid container>
                    <Grid item xs={6}>
                      Full name :
                    </Grid>
                    <Grid item xs={6}>
                      {userInfo.firstName ? userInfo.firstName : ""}
                      {userInfo.lastName ? " " + userInfo.lastName : ""}
                    </Grid>
                  </Grid>
                </Stack>
                <Stack
                  sx={{
                    minHeight: 34,
                    borderBottom: "1px solid #707070",
                    justifyContent: "flex-end",
                  }}
                >
                  <Grid container>
                    <Grid item xs={6}>
                      Phone number :
                    </Grid>
                    <Grid item xs={6}>
                      {userInfo.phone ? userInfo.phone : ""}
                    </Grid>
                  </Grid>
                </Stack>
                <Stack
                  sx={{
                    minHeight: 34,
                    borderBottom: "1px solid #707070",
                    justifyContent: "flex-end",
                  }}
                ></Stack>
                <Button
                  variant="contained"
                  disableElevation
                  onClick={handleSettingsModalOpen}
                  sx={{
                    height: 30,
                    backgroundColor: "#0A284A",
                    ":hover": {
                      backgroundColor: "#1B395B",
                    },
                    textTransform: "none",
                    marginTop: "23px",
                    fontFamily: "Akrobat",
                    fontSize: "14px",
                  }}
                >
                  Update Account
                </Button>
              </Stack>
            </Stack>
            <ChangeDetailsDialog
              modalOpen={settingsModalOpen}
              handleModalClose={handleSettingsModalClose}
            />
          </Stack>
        </Stack>
        <ChangePassword />
      </Stack>
      <Stack
        sx={{
          gap: "21px",
          width: "100%",
          marginTop: "21px",
          flexDirection: {
            xs: "column",
            sm: "row",
          },
          height: { sm: 278 },
        }}
      >
        <Stack
          sx={{
            backgroundColor: "#FAFAFA",
            flex: 0.95,
            border: "1px solid #70707020",
            paddingX: "14px",
            paddingY: "16px",
          }}
        >
          <Stack
            sx={{
              fontSize: "18px",
              fontWeight: "bold",
            }}
          >
            App Settings
          </Stack>

          <Stack
            sx={{
              marginTop: "32px",
              fontSize: "16px",
              fontWeight: 600,
              gap: 3,
            }}
          >
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              gap={2}
              maxWidth={350}
            >
              Handedness
              <Stack>{userInfo.handed}</Stack>
            </Stack>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              gap={2}
              maxWidth={350}
            >
              Term and Conditions Acceptance
              <Stack>Accepted</Stack>
            </Stack>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              gap={2}
              maxWidth={350}
            >
              Biometric Sign in
              <Stack>{userInfo.isBiometric ? "Setup" : "Not"}</Stack>
            </Stack>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              gap={2}
              maxWidth={350}
            >
              Task Point Value
              <Stack>100</Stack>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          sx={{
            backgroundColor: "#FAFAFA",
            flex: 1,
            border: "1px solid #70707020",
          }}
        >
          <Stack
            sx={{
              marginX: "16px",
              marginY: "14px",
              height: "100%",
              gap: 2,
            }}
          >
            <Stack
              sx={{
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              Manage Subscriptions
            </Stack>

            <Stack
              sx={{
                flex: 1,
                fontSize: "16px",
                gap: 2,
              }}
            >
              <Stack
                sx={{
                  gap: 3,
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <Stack sx={{ fontWeight: 600, flexDirection: "row" }}>
                  {"prtnr | "}
                  {userInfo?.stripeSubscriptionResponse?.status === "active" ? (
                    <>
                      {"premium - alpha ("}
                      <Stack
                        sx={{
                          textDecoration: userInfo.stripeSubscriptionResponse
                            ?.discount?.coupon?.valid
                            ? "line-through"
                            : "none",
                        }}
                      >
                        {`$${(
                          userInfo.stripeSubscriptionResponse?.plan?.amount /
                            100 ?? 0
                        ).toFixed(2)}`}
                      </Stack>
                      &nbsp;
                      {userInfo.stripeSubscriptionResponse?.discount?.coupon
                        ?.valid &&
                        `$${(
                          (userInfo.stripeSubscriptionResponse?.plan?.amount *
                            (100 -
                              userInfo.stripeSubscriptionResponse?.discount
                                ?.coupon?.percent_off ?? 0)) /
                            10000 ?? 0
                        ).toFixed(2)}`}
                      &nbsp;
                      {"a month)"}
                    </>
                  ) : (
                    "standard - alpha"
                  )}
                </Stack>
                <Stack
                  sx={{
                    fontWeight: "bold",
                    fontSize: 20,
                    alignItems: "center",
                  }}
                >
                  {userInfo?.stripeSubscriptionResponse?.status === "active" ? (
                    <>
                      Active
                      <Stack fontSize={14}>
                        {userInfo?.stripeSubscriptionResponse
                          ?.cancel_at_period_end === true ? (
                          <>{`(Cancel at ${new Date(
                            (userInfo?.stripeSubscriptionResponse?.cancel_at ??
                              0) * 1000
                          ).toLocaleDateString("en-US", {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                          })})`}</>
                        ) : (
                          <>
                            Current period:&nbsp;
                            {new Date(
                              (userInfo?.stripeSubscriptionResponse
                                ?.current_period_start ?? 0) * 1000
                            ).toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                            })}
                            ~
                            {new Date(
                              (userInfo?.stripeSubscriptionResponse
                                ?.current_period_end ?? 0) * 1000
                            ).toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                            })}
                          </>
                        )}
                      </Stack>
                    </>
                  ) : (
                    <>
                      {userInfo?.stripeSubscriptionResponse?.status ===
                      "canceled"
                        ? "Canceled"
                        : "free"}
                    </>
                  )}
                </Stack>
                <Stack sx={{ fontWeight: 600 }}>{`email: ${
                  userInfo.email ? userInfo.email : ""
                }`}</Stack>
                <Button
                  variant="contained"
                  disableElevation
                  onClick={() => {
                    navigate("/billing/subscribe");
                  }}
                  sx={{
                    height: 30,
                    backgroundColor: "#0A284A",
                    ":hover": {
                      backgroundColor: "#1B395B",
                    },
                    textTransform: "none",
                    marginTop: "-5px",
                    fontFamily: "Akrobat",
                    fontSize: "14px",
                    width: { xs: "100%", sm: 318 },
                  }}
                >
                  Manage Subscriptions
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default AccountSetting;
