import { Button } from "@mui/material";

const ConfirmButton = ({ onClick, disabled, children }) => {
  return (
    <Button
      onClick={onClick}
      disabled={disabled === true ? true : false}
      sx={{
        height: 36,
        minWidth: 70,
        borderRadius: 0,
        textTransform: "none",
        fontSize: "14px",
        fontWeight: "bold",
        color: "white",
        backgroundColor: "#0A284A",
        fontFamily: "Akrobat",
        ":hover": {
          backgroundColor: "#2C4A6C",
        },
        ":disabled": {
          color: "#777",
          backgroundColor: "#ccc",
        },
      }}
    >
      {children}
    </Button>
  );
};
export default ConfirmButton;
