import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Input,
  Stack,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import {  toast } from "react-toastify";

import { sendPasswordResetEmail, signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { collection, getDocs, query, where } from "firebase/firestore";
import { updateUserInfo } from "../utils/actions";

import {
  firebaseAuth,
  firebaseFirestore,
  firebaseGoogleAuthProvider,
} from "../utils/firebase";

import GoogleSvg from "../assets/svgs/GoogleSvg";
import AppleSvg from "../assets/svgs/AppleSvg";

const LoginInput = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <Stack
      sx={{
        height: 43,
        width: 323,
        background: "white",
        borderRadius: 30,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Input
        disableUnderline
        endAdornment={
          props.type === "password" && (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword((show) => !show)}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )
        }
        sx={{
          height: 25,
          width: 300,
          fontFamily: "Akrobat",
          fontWeight: "bold",
          fontSize: 20,
          color: "#0A284A",
          "& .MuiInputBase-input": {
            padding: 0,
          },
        }}
        {...props}
        type={
          props.type === "password"
            ? showPassword
              ? "text"
              : "password"
            : props.type
        }
      />
    </Stack>
  );
};

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginFailedFlag, setLoginFailedFlag] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [logining, setLogining] = useState(false);
  const [googleLogining, setGoogleLogining] = useState(false);
  const [appleLogining, setAppleLogining] = useState(false);
  const [splashImageId, setSplashImageId] = useState(null);
  let navigate = useNavigate();

  function handleEmailChange(event) {
    setEmail(event.target.value);
  }

  function handlePasswordChange(event) {
    setPassword(event.target.value);
  }

  const setUserInfotoSessionStorage = async (email) => {
    const registrationRef = collection(firebaseFirestore, "Registration");

    try {
      const q = query(registrationRef, where("email", "==", email.toLowerCase()));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.size > 0) {
        const userInfo = querySnapshot.docs[0];
        updateUserInfo(userInfo.data());
        return true;
      }
    } catch (error) {
      console.log(error);
    }
    return false;
  };

  const loginButtonClickHandle = async (event) => {
    event.preventDefault();
    setLogining(true);
    await signInWithEmailAndPassword(firebaseAuth, email, password)
      .then(async (userCredential) => {
        // Sign-up successful, perform any necessary actions
        const user = userCredential.user;
        const email = user.email.toLowerCase();
        const result = await setUserInfotoSessionStorage(email);

        result === true
          ? navigate("/billing")
          : setErrorMsg("This user is not registerd.");
      })
      .catch((err) => {
        switch (err.code) {
          case "auth/Invalid-email":
            setErrorMsg("This email is invalid.");
            break;
          case "auth/user-disabled":
            setErrorMsg("This user is disabled.");
            break;
          case "auth/user-not-found":
            setErrorMsg("This user is not found.");
            break;
          case "auth/wrong-password":
            setErrorMsg("Password is incorrect.");
            break;
          default:
            setErrorMsg("oh no, this is not right.");
            break;
        }
      });
    setLoginFailedFlag(true);
    setLogining(false);
    //        setLoginFailedFlag(true);
  };
  const googleLogin = async (event) => {
    event.preventDefault();
    setGoogleLogining(true);
    await signInWithPopup(firebaseAuth, firebaseGoogleAuthProvider)
      .then(async (result) => {
        // Signed in with Google
        const user = result.user;
        const email = user.email.toLowerCase();

        const res = await setUserInfotoSessionStorage(email);

        res === true
          ? navigate("/billing")
          : setErrorMsg("This user is not registerd.");
      })
      .catch((error) => {
        // An error occurred
        console.error(error);
      });
    setGoogleLogining(false);
  };

  const appleLogin = async (event) => {
    event.preventDefault();
    setAppleLogining(true);
    setAppleLogining(false);
  };

  const passwordInputRef = useRef(null);
  const loginButtonRef = useRef(null);

  const handleUsernameKeyDown = (event) => {
    if (event.key === "Enter") passwordInputRef.current.focus();
  };
  const handlePasswordKeyDown = (event) => {
    if (event.key === "Enter") loginButtonRef.current.focus();
  };

  const resetPassword = () => {
    sendPasswordResetEmail(firebaseAuth, email)
      .then(() => {
        toast.success("Password reset email sent!");
      })
      .catch((error) => {
        const errorCode = error.code;
        console.log(errorCode);
        switch (errorCode) {
          case "auth/missing-email":
            toast.error("Email address is missed.");
            break;
          case "auth/invalid-email":
            toast.error("Email address is invalid.");
            break;
          case "auth/user-not-found":
            toast.error("This user is not found.");
            break;
          default:
            toast.error("Firebase Error!");
        }
      });
  }
  
  useEffect(() => {
    console.log("res");
    setSplashImageId(Math.floor(Math.random() * 7 + 1));
  }, []);

  return (
    <Stack
      sx={{
        backgroundImage: splashImageId
          ? `url("/assets/images/splashimage/s${splashImageId}.png")`
          : null,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "100vh",
        width: "100vw",
      }}
    >
      <Stack
        sx={{
          position: "absolute",
          height: "100%",
          width: "100%",
          backdropFilter: "blur(4px) brightness(0.3)",
          color: "white",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Stack
          sx={{
            flexDirection: "row",
            marginBottom: "17px",
          }}
        >
          <Stack
            sx={{
              fontSize: 22,
              fontWeight: "bold",
            }}
          >
            prtnr.&nbsp;
          </Stack>
          <Stack
            sx={{
              fontSize: 16,
              paddingTop: "6px",
            }}
          >
            be btr
          </Stack>
        </Stack>
        <Stack gap="8px">
          <LoginInput
            placeholder="username"
            value={email}
            onChange={handleEmailChange}
            type="text"
            onKeyDown={handleUsernameKeyDown}
          />
          <LoginInput
            placeholder="password"
            value={password}
            onChange={handlePasswordChange}
            type="password"
            onKeyDown={handlePasswordKeyDown}
            inputRef={passwordInputRef}
          />
        </Stack>
        <Stack height="40px">
          {loginFailedFlag ? (
            <Stack
              sx={{
                flexDirection: "row",
                marginTop: "9px",
                alignItems: "center",
                color: "#C40F0F",
              }}
            >
              <Stack
                sx={{
                  fontSize: 14,
                  fontWeight: 600,
                }}
              >
                {errorMsg}&nbsp;
              </Stack>
              <Stack
                sx={{
                  fontSize: 14,
                  fontWeight: "bolder",
                }}
              >
                please try again
              </Stack>
            </Stack>
          ) : (
            <></>
          )}
        </Stack>
        <Button
          variant="contained"
          disableElevation
          onClick={loginButtonClickHandle}
          ref={loginButtonRef}
          disabled={logining}
          sx={{
            height: 43,
            width: 323,
            borderRadius: 20,
            fontFamily: "Akrobat",
            fontWeight: "bold",
            backgroundColor: "black",
            ":hover": {
              backgroundColor: "#111",
            },
            ":disabled": {
              backgroundColor: "#111B",
              color: "#fffb",
            },
            fontSize: 20,
            textTransform: "none",
          }}
        >
          {logining ? (
            <CircularProgress sx={{ color: "#ccc" }} thickness={6} size={30} />
          ) : (
            "login"
          )}
        </Button>
        <Stack
          sx={{
            flexDirection: "row",
            marginTop: "17px",
            alignItems: "center",
          }}
        >
          <Stack
            sx={{
              fontFamily: "Akrobat",
              fontSize: 14,
              fontWeight: "bold",
            }}
          >
            I seriously&nbsp;
          </Stack>
          <Stack
            sx={{
              fontFamily: "Akrobat",
              fontSize: 14,
            }}
          >
            can’t find my password
          </Stack>
        </Stack>

        <Button
          variant="contained"
          disableElevation
          onClick={googleLogin}
          disabled={googleLogining}
          sx={{
            height: 43,
            width: 323,
            borderRadius: 20,
            marginTop: 2,
            fontFamily: "Akrobat",
            fontWeight: "bold",
            backgroundColor: "#03b",
            ":hover": {
              backgroundColor: "#14d",
            },
            ":disabled": {
              backgroundColor: "#14DB",
            },
            fontSize: 20,
            textTransform: "none",
            gap: 2,
          }}
        >
          {googleLogining ? (
            <CircularProgress sx={{ color: "#ccc" }} thickness={6} size={30} />
          ) : (
            <>
              Sign In with Google{" "}
              <Stack sx={{ width: 30, height: 30 }}>
                <GoogleSvg />
              </Stack>
            </>
          )}
        </Button>

        <Button
          variant="contained"
          disableElevation
          onClick={appleLogin}
          disabled={true || appleLogining}
          sx={{
            height: 43,
            width: 323,
            borderRadius: 20,
            marginTop: 2,
            fontFamily: "Akrobat",
            fontWeight: "bold",
            backgroundColor: "#666",
            ":hover": {
              backgroundColor: "#777",
            },
            ":disabled": {
              backgroundColor: "#777B",
            },
            fontSize: 20,
            textTransform: "none",
            gap: 2,
          }}
        >
          {appleLogining ? (
            <CircularProgress sx={{ color: "#ccc" }} thickness={6} size={30} />
          ) : (
            <>
              Sign In with Apple{" "}
              <Stack sx={{ width: 30, height: 30 }}>
                <AppleSvg />
              </Stack>
            </>
          )}
        </Button>
        <Button
          variant="contained"
          disableElevation
          onClick={resetPassword}
          sx={{
            color: "white",
            marginTop: 1,
            fontFamily: "Akrobat",
            backgroundColor: "#0000",
            ":hover": {
              backgroundColor: "#0000",
            },
            ":disabled": {
              backgroundColor: "#0000",
            },
            borderRadius: 10,
            fontSize: 16,
            textTransform: "none",
          }}
          TouchRippleProps={{ disableTouchRipple: true }}
        >
          Forget your password?&nbsp;<Stack fontSize={18} fontWeight="bold">Click here.</Stack>
        </Button>
      </Stack>
    </Stack>
  );
};

export default LoginPage;
