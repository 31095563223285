import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import {
  collection,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import LoginPage from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import { firebaseAuth, firebaseFirestore } from "./utils/firebase";
import "./App.css";

import { removeUserInfo, updateUserInfo } from "./utils/actions";
import PasswordResetPage from "./pages/PasswordResetPage";
import { Backend_Stripe_API, environment } from "./utils/configs";
import { toast } from "react-toastify";

function App() {
  const [signedIn, setSignedIn] = useState(undefined);
  const dispatch = useDispatch();

  useEffect(() => {
    onAuthStateChanged(firebaseAuth, async (user) => {
      if (user) {
        setSignedIn(true);
        try {
          const registrationRef = collection(firebaseFirestore, "Registration");
          const q = query(registrationRef, where("email", "==", user.email));
          const querySnapshot = await getDocs(q);
          if (querySnapshot.size > 0) {
            const userInfo = querySnapshot.docs[0];
            const userInfoData = userInfo.data();
            dispatch(updateUserInfo(userInfoData));
            if (!userInfoData.stripeCustomerID) {
              const response = await axios.post(
                `${Backend_Stripe_API}/customers/create`,
                {
                  environment,
                  email: user.email,
                }
              );
              dispatch(updateUserInfo({ stripeCustomerID: response.data?.id }));
              updateDoc(userInfo.ref, { stripeCustomerID: response.data?.id });
            }

            if (userInfoData.stripeSubscriptionID) {
              const response = await axios.post(
                `${Backend_Stripe_API}/subscriptions/retrieve`,
                {
                  environment,
                  subscriptionId: userInfoData.stripeSubscriptionID,
                }
              );
              dispatch(
                updateUserInfo({
                  stripeSubscriptionStatus: response.data?.status,
                  stripeSubscriptionResponse: response.data,
                })
              );
              updateDoc(userInfo.ref, {
                stripeSubscriptionStatus: response.data?.status,
                stripeSubscriptionResponse: response.data,
              });
            }
          }
        } catch (error) {
          console.error(error);
          error?.message && toast.error(error?.message);
          error?.response?.data?.raw?.message &&
            toast.info(error?.response?.data?.raw?.message);
        }
      } else {
        dispatch(removeUserInfo());
        setSignedIn(false);
      }
    });
  }, [dispatch]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Router>
        <Routes>
          <Route exact path="/" element={<LoginPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/__/auth/action" element={<PasswordResetPage />} />
          {signedIn && (
            <>
              <Route path="/billing" element={<Dashboard />} />
              <Route path="/billing/account" element={<Dashboard />} />
              <Route path="/billing/subscribe" element={<Dashboard />} />
            </>
          )}
          <Route
            path="*"
            element={
              signedIn === undefined ? (
                <></>
              ) : (
                <Navigate to={signedIn ? "/billing" : "/login"} />
              )
            }
          />
        </Routes>
      </Router>
    </LocalizationProvider>
  );
}

export default App;
