import { useState } from "react";
import { Button, Grid, Stack } from "@mui/material";

import CheckSvg from "../assets/svgs/CheckSvg";
import SubscribeDialog from "../components/manageSubscriptions/SubscribeDialog";
import { useSelector } from "react-redux";

const ManageSubscriptions = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const userInfo = useSelector((state) => state);
  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Stack
        sx={{
          border: "1px solid #70707080",
          height: {
            sm: 160,
          },
          backgroundColor: "#F6F5F5",
          fontSize: {
            xs: "20px",
            sm: "35px",
          },
          padding: { xs: 2, sm: "inherit" },
          fontWeight: "bold",
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
          overflowWrap: "break-word",
          wordWrap: "break-word",
          overflowY: "auto",
        }}
      >
        Subscribe now to lock in long term perks and new features.
        <br />
        Subscribe now and save til 2025.
      </Stack>
      <Stack
        sx={{
          fontSize: "18px",
          maxWidth: 980,
          textAlign: "center",
          marginTop: "16px",
        }}
      >
        <Stack>
          From now until the end of the month, get a massive promo of {} off the
          monthly price of prtnr, with a full subscription you get the same
          quality content, but cheaper. Also, you get sneak peak access to new
          features before the general public, plus exclusive access to prtnr
          perks where we find exclusive access to unique promos and perks...were
          working a car deal, and some way to actually get the food you agreed
          on in 3 choices. Finally, as we add new experts in our prtnr partners
          section, we will some exclusive opportunities to connect with the
          partners directly.
        </Stack>
        <br />
        <Stack>
          What are you waiting for… (wait is this really the price) …someone
          should change that, the math isn’t mathing
        </Stack>
      </Stack>
      <Stack
        sx={{
          fontSize: "28px",
          paddingX: "5px",
          marginTop: "16px",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        Get sneak preview access to all the new features below before everyone
        else, plus all the other stuff I said above.
      </Stack>
      <Grid
        container
        spacing="12px"
        sx={{
          marginTop: "34px",
          paddingX: "20px",
        }}
      >
        {[
          "The prtnr podcast",
          "prtnr Games",
          "Couplet - prtnr community",
          "A.I. coaching - Coming in 2025",
          "prtnr partners - Full Access to relationship experts and their strategies to make relationships better",
          "The Gauntlet - Monthly themed conversations with your partner to pull out deeper conversations",
          "Plus other stuff we think of",
        ].map((item, idx) => (
          <Grid item xs={12} sm={6} key={idx}>
            <Stack
              sx={{
                gap: "26px",
                flexDirection: "row",
                alignItems: "flex-start",
              }}
            >
              <Stack
                sx={{
                  minHeight: 23,
                  minWidth: 23,
                }}
              >
                <CheckSvg />
              </Stack>
              <Stack
                sx={{
                  maxWidth: 337,
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                {item}
              </Stack>
            </Stack>
          </Grid>
        ))}
      </Grid>
      <Stack
        sx={{
          marginTop: "45px",
          gap: "20px",
          alignItems: "flex-start",
          flexDirection: { xs: "column", sm: "row" },
          height: { md: 85 },
        }}
      >
        <Button
          sx={{
            border: "3px solid #70707028",
            backgroundColor: "white",
            flex: 1,
            height: "100%",
            width: "100%",
            borderRadius: "11px",
            textTransform: "none",
            fontSize: { xs: "20px", md: "27px" },
            fontWeight: "bold",
            color: "#0A284A70",
            fontFamily: "Akrobat",
          }}
        >
          Free
        </Button>
        <Button
          onClick={handleModalOpen}
          disabled={userInfo?.stripeSubscriptionResponse?.status === "active"}
          sx={{
            flex: 1,
            borderRadius: "11px",
            textTransform: "none",
            fontSize: { xs: "20px", md: "27px" },
            fontWeight: "bold",
            height: "100%",
            width: "100%",
            color: "white",
            backgroundColor: "#0A284A",
            fontFamily: "Akrobat",
            ":hover": {
              backgroundColor: "#2C4A6C",
            },
            ":disabled": {
              color: "#777",
              backgroundColor: "#ccc",
            },
          }}
        >
          Save 50% -&nbsp;
          <span style={{ textDecoration: "line-through" }}>$20.0</span>
          &nbsp;$10 a month
        </Button>
      </Stack>
      <Button
        sx={{
          height: 85,
          marginTop: "45px",
          borderRadius: "11px",
          textTransform: "none",
          fontSize: "27px",
          fontWeight: "bold",
          color: "white",
          backgroundColor: "#0A284A",
          fontFamily: "Akrobat",
          ":hover": {
            backgroundColor: "#2C4A6C",
          },
        }}
      >
        Purchase Subscription
      </Button>
      <SubscribeDialog
        modalOpen={modalOpen}
        handleModalClose={handleModalClose}
      />
    </>
  );
};

export default ManageSubscriptions;
