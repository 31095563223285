import { Button, Input, Stack } from "@mui/material";
import { useState } from "react";
import { firebaseAuth } from "../../utils/firebase";
import { getIdToken, updatePassword } from "firebase/auth";
import { toast } from "react-toastify";

const ChangePassword = () => {
  const [pass, setPass] = useState("");
  const [repass, setRepass] = useState("");
  const [helperText, setHelperText] = useState("");
  const handlePasswordChange = async () => {
    if (pass !== repass) {
      setHelperText("Password does not match.");
      toast.error("Password does not match.");
    } else if (pass.length < 6) {
      toast.error("Password should be longer than 6 characters.");
      setHelperText("Password should be longer than 6 characters.");
    } else {
      await getIdToken(firebaseAuth.currentUser, true);
      await updatePassword(firebaseAuth.currentUser, pass)
        .then(() => {
          toast.success("Password is updated successful.");
          setHelperText("Password is updated successful.");
        })
        .catch((error) => {
          setHelperText("Firebase error!");
          toast.error("Firebase Error!");
        });
    }
  };
  return (
    <Stack
      sx={{
        marginTop: "18px",
        marginBottom: "24px",
        paddingX: "33px",
        width: { xs: "calc(100% - 70px)", sm: 500, md: 262 },
        maxWidth: 400,
        justifyContent: "space-between",
        borderLeft: { md: "1px solid #70707080" },
      }}
    >
      <Stack
        sx={{
          marginTop: "22px",
        }}
      >
        Change Password
      </Stack>
      <Input
        disableUnderline
        type="password"
        value={pass}
        onChange={(e) => setPass(e.target.value)}
        sx={{
          height: 30,
          backgroundColor: "white",
          border: "1px solid #70707080",
          marginTop: "19px",
          input: {
            padding: "2px 1px",
          },
        }}
      />
      <Input
        disableUnderline
        type="password"
        value={repass}
        onChange={(e) => setRepass(e.target.value)}
        sx={{
          height: 30,
          backgroundColor: "white",
          border: "1px solid #70707080",
          marginTop: "16px",
          input: {
            padding: "2px 1px",
          },
        }}
      />

      <Button
        variant="contained"
        disableElevation
        onClick={handlePasswordChange}
        sx={{
          height: 30,
          backgroundColor: "#0A284A",
          ":hover": {
            backgroundColor: "#1B395B",
          },
          textTransform: "none",
          marginTop: "25px",
          fontFamily: "Akrobat",
          fontSize: "14px",
        }}
      >
        Change Passwords
      </Button>

      <Stack
        sx={{
          fontSize: "14px",
          textAlign: "center",
          marginTop: "6px",
          height: "34px",
        }}
      >
        {helperText}
      </Stack>
    </Stack>
  );
};

export default ChangePassword;
