/* eslint-disable react/jsx-pascal-case */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  collection,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";

import {
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  TextField,
  InputAdornment,
} from "@mui/material";
import { CalendarMonth, CreditCard, Lock } from "@mui/icons-material";
import KeyIcon from "@mui/icons-material/Key";
import Card from "react-credit-cards";

import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";

import CancelButton from "../buttons/CancelButton";
import ConfirmButton from "../buttons/ConfirmButton";
import "react-credit-cards/es/styles-compiled.css";
import { firebaseFirestore } from "../../utils/firebase";
import { updateUserInfo } from "../../utils/actions";
import { toast } from "react-toastify";
import {
  Backend_Stripe_API,
  environment,
  isProductionMode,
} from "../../utils/configs";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const stripePromise = loadStripe(
  isProductionMode
    ? process.env.REACT_APP_STRIPE_PROD_PUBLISHABLE_API_KEY
    : process.env.REACT_APP_STRIPE_TEST_PUBLISHABLE_API_KEY
);

const stripeElementOption = {
  showIcon: true,
  style: {
    base: {
      fontWeight: 400,
      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
      fontSize: "16px",
      height: "23px",
      padding: 5,
      lineHeight: "32px",
      letterSpacing: "0.15008px",

      "::placeholder": {
        color: "rgba(0,0,0,0.6)",
      },
      ":hover": {},
      ":focus": {},
    },
  },
};

const CreditCardLabel = (props) => {
  return (
    <Stack
      sx={{
        color: "inherit",
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        fontSize: "12px",
        transition:
          "color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
      }}
    >
      {props.children}
    </Stack>
  );
};

const CreditCardHelperText = (props) => {
  return (
    <Stack
      sx={{
        color: "inherit",
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        fontSize: "12px",
        marginTop: "3px",
        height: props.children ? 14 : 0,
        transition: "height 300ms ease",
      }}
    >
      {props.children}
    </Stack>
  );
};

const CreditCardFormControl = (props) => {
  const mainColor =
    props.error === true
      ? "#d32f2f"
      : props.focused === true
      ? "#1976d2"
      : "#0007";
  return (
    <Stack
      sx={{
        color: mainColor,
        width: "100%",
      }}
    >
      <CreditCardLabel>{props.label}</CreditCardLabel>
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          gap: "8px",
          position: "relative",
          color: "#0007",
          "::before": {
            position: "absolute",
            content: "' '",
            borderBottom: `1px solid ${mainColor}`,
            left: 0,
            right: 0,
            bottom: 0,
            transition:
              "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          },
          "&:hover::before":
            props.error !== true
              ? {
                  borderBottom: "2px solid #000",
                }
              : {},
          "::after": {
            position: "absolute",
            content: "''",
            borderBottom: `2px solid ${mainColor}`,
            left: 0,
            right: 0,
            bottom: 0,
            transform: props.focused === true ? "scaleX(1)" : "scaleX(0)",
            transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
          },
        }}
      >
        {props.type !== "cardNumber" && props.startAdornment}
        {props.type === "cardNumber" && (
          <CardNumberElement
            onChange={props.onChange}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            options={stripeElementOption}
          />
        )}
        {props.type === "cardExpiry" && (
          <CardExpiryElement
            onChange={props.onChange}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            options={stripeElementOption}
          />
        )}
        {props.type === "cardCvc" && (
          <CardCvcElement
            onChange={props.onChange}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            options={stripeElementOption}
          />
        )}
      </Stack>
      <CreditCardHelperText>{props.helperText}</CreditCardHelperText>
    </Stack>
  );
};

const SubscribeDialog_ = ({ modalOpen, handleModalClose }) => {
  const stripe = useStripe();
  const elements = useElements();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state);
  const [price, setPrice] = useState(2000);
  const [discount, setDiscount] = useState(0);
  const [creditCardFocused, setCreditCardFocused] = useState("");
  const [creditCard, setCreditCard] = useState({
    cardNumber: {},
    cardExpiry: {},
    cardCvc: {},
  });
  const [couponCode, setCouponCode] = useState("");
  const [processing, setProcessing] = useState(false);
  const [couponProcessing, setCouponProcessing] = useState(false);
  /*
  useEffect(async () => {
    try {
      const response = await axios.post(
        `${Backend_Stripe_API}/prices/retrieve`,
        {
          environment,
        }
      );
      setPrice(response.data?.unit_amount ?? 0);
    } catch (error) {
      error?.message && toast.error(error?.message);
      error?.response?.data?.raw?.message &&
        toast.info(error?.response?.data?.raw?.message);
      console.error(error);
    }
  }, []);*/

  const handleCreditCardChange = (e) => {
    if (e.elementType === "cardNumber") {
      document.getElementsByClassName(
        "rccs"
      )[0].children[0].className = `rccs__card rccs__card--${
        e.brand ? (e.brand === "diners" ? "dinersclub" : e.brand) : "unknown"
      }`;
    }
    setCreditCard((prev) => ({ ...prev, [e.elementType]: e }));
  };

  const handleCreditCardFocus = (e) => {
    if (e.elementType === "cardCvc") {
      document.getElementsByClassName(
        "rccs"
      )[0].children[0].className = `rccs__card rccs__card--${
        creditCard.cardNumber?.brand ? creditCard.cardNumber.brand : "unknown"
      } rccs__card--flipped`;
    } else {
      document.getElementsByClassName(
        "rccs"
      )[0].children[0].className = `rccs__card rccs__card--${
        creditCard.cardNumber?.brand ? creditCard.cardNumber.brand : "unknown"
      }`;
    }
    setCreditCardFocused(e.elementType);
  };

  const handleCreditCardBlur = (e) => {
    setCreditCardFocused((prev) =>
      prev === e.elementType ? undefined : prev === e.elementType
    );
  };
  const handleConfirmCouponCode = async (event) => {
    event.preventDefault();
    setCouponProcessing(true);
    try {
      const response = await axios.post(
        `${Backend_Stripe_API}/coupons/retrieve`,
        {
          environment,
          couponId: couponCode,
        }
      );
      if (response.data?.valid) setDiscount(response.data?.percent_off ?? 0);
    } catch (error) {
      error?.message && toast.error(error?.message);
      error?.response?.data?.raw?.message &&
        toast.info(error?.response?.data?.raw?.message);
      console.error(error);
    }
    setCouponProcessing(false);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);

    try {
      const cardNumberElement = elements.getElement(CardNumberElement);
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardNumberElement,
        billing_details: {
          email: userInfo.email,
        },
      });
      toast.info("Payment method has been created.");

      if (error) {
        console.log("[error]", error);
        error.message && toast.error(error.message);
      } else {
        const { id: paymentMethodId } = paymentMethod;
        await axios.post(`${Backend_Stripe_API}/paymentMethods/attach`, {
          environment,
          paymentMethodId: paymentMethodId,
          customerId: userInfo.stripeCustomerID,
        });
        toast.info("Payment method attached.");
        await axios.post(`${Backend_Stripe_API}/customers/update`, {
          environment,
          customerId: userInfo.stripeCustomerID,
          paymentMethodId: paymentMethodId,
        });
        toast.info("Your payment info updated.");
        const response = await axios.post(
          `${Backend_Stripe_API}/subscriptions/create`,
          {
            environment,
            customerId: userInfo.stripeCustomerID,
            coupon: couponCode,
          }
        );
        toast.info("Subscription created.");

        const registrationRef = collection(firebaseFirestore, "Registration");

        dispatch(
          updateUserInfo({
            stripeSubscriptionID: response.data?.id,
            stripeSubscriptionStatus: response.data?.status,
            stripeSubscriptionResponse: response.data,
          })
        );
        const q = query(
          registrationRef,
          where("email", "==", userInfo.email.toLowerCase())
        );
        const querySnapshot = await getDocs(q);
        if (querySnapshot.size > 0) {
          const userInfo = querySnapshot.docs[0];
          await updateDoc(userInfo.ref, {
            stripeSubscriptionID: response.data?.id,
            stripeSubscriptionStatus: response.data?.status,
            stripeSubscriptionResponse: response.data,
          });
          navigate("/billing/account");
        }
      }
    } catch (error) {
      error?.message && toast.error(error?.message);
      error?.response?.data?.raw?.message &&
        toast.info(error?.response?.data?.raw?.message);
      console.error(error);
    }
    setProcessing(false);
    handleModalClose();
  };

  return (
    <Dialog
      open={modalOpen}
      onClose={handleModalClose}
      sx={{
        "& .MuiPaper-root": {
          borderRadius: 0,
          margin: 0,
          maxHeight: "100%",
        },
      }}
    >
      <DialogTitle sx={{ fontFamily: "Akrobat", fontWeight: "bold" }}>
        Subscribe
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ fontFamily: "Akrobat" }}>
          To subscribe to this website, please enter your credit card details
          here.
        </DialogContentText>
        <Stack
          sx={{
            marginTop: "10px",
            gap: 2,
            alignItems: "center",
            ".rccs__card--back, .rccs__card--front": {
              backgroundImage: "url('/assets/images/card-background.png')",
            },
          }}
        >
          <Card
            number=""
            name=""
            expiry=""
            cvc="●●●"
            issuer="jcb"
            type="jcb"
            /*focused={
              { cardNumber: "number", cardExpiry: "expiry", cardCvc: "cvc" }[
                creditCardFocused
              ]
            }*/
          />
          <form style={{ width: "100%" }}>
            <Stack
              sx={{
                marginTop: "10px",
                gap: 2,
              }}
            >
              <CreditCardFormControl
                label="Card Number:"
                type="cardNumber"
                startAdornment={<CreditCard />}
                onChange={handleCreditCardChange}
                onFocus={handleCreditCardFocus}
                onBlur={handleCreditCardBlur}
                focused={creditCardFocused === "cardNumber"}
                error={creditCard.cardNumber.error !== undefined}
                helperText={
                  creditCard.cardNumber.error !== undefined
                    ? creditCard.cardNumber.error.message
                    : ""
                }
              />
              <CreditCardFormControl
                label="Expiration Date:"
                type="cardExpiry"
                startAdornment={<CalendarMonth />}
                onChange={handleCreditCardChange}
                onFocus={handleCreditCardFocus}
                onBlur={handleCreditCardBlur}
                focused={creditCardFocused === "cardExpiry"}
                error={creditCard.cardExpiry.error !== undefined}
                helperText={
                  creditCard.cardExpiry.error !== undefined
                    ? creditCard.cardExpiry.error.message
                    : ""
                }
              />
              <CreditCardFormControl
                label="Private Code:"
                type="cardCvc"
                startAdornment={<Lock />}
                onChange={handleCreditCardChange}
                onFocus={handleCreditCardFocus}
                onBlur={handleCreditCardBlur}
                focused={creditCardFocused === "cardCvc"}
                error={creditCard.cardCvc.error !== undefined}
                helperText={
                  creditCard.cardCvc.error !== undefined
                    ? creditCard.cardCvc.error.message
                    : ""
                }
              />
              <Stack
                sx={{
                  flexDirection: "row",
                  opacity: couponCode ? 1 : 0.4,
                  width: "100%",
                  justifyContent: "space-between",
                  gap: 2,
                  alignItems: "center",
                }}
              >
                <TextField
                  label="Promotion Code"
                  size="small"
                  value={couponCode}
                  onChange={(e) => setCouponCode(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <KeyIcon />
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    flex: 1,
                  }}
                />
                <ConfirmButton
                  onClick={handleConfirmCouponCode}
                  disabled={couponProcessing || processing}
                >
                  {couponProcessing ? (
                    <CircularProgress
                      sx={{ color: "#ccc" }}
                      thickness={6}
                      size={22}
                    />
                  ) : (
                    "Add Promo"
                  )}
                </ConfirmButton>
              </Stack>
            </Stack>
          </form>
        </Stack>
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={handleModalClose} />
        <ConfirmButton
          onClick={handleSubmit}
          disabled={
            processing ||
            couponProcessing ||
            creditCard.cardNumber.complete !== true ||
            creditCard.cardExpiry.complete !== true ||
            creditCard.cardCvc.complete !== true
          }
        >
          {processing ? (
            <CircularProgress sx={{ color: "#ccc" }} thickness={6} size={22} />
          ) : (
            <>
              {discount === 0 ? (
                <>{`Subscribe ($${(price / 100).toFixed(2)} a month)`}</>
              ) : (
                <>
                  Subscribe ($
                  <span style={{ textDecoration: "line-through" }}>
                    {(price / 100).toFixed(2)}
                  </span>
                  &nbsp;${((price * (100 - discount)) / 10000).toFixed(2)}
                  &nbsp;a month)
                </>
              )}
            </>
          )}
        </ConfirmButton>
      </DialogActions>
    </Dialog>
  );
};

const SubscribeDialog = ({ modalOpen, handleModalClose }) => {
  return (
    <Elements stripe={stripePromise}>
      <SubscribeDialog_
        modalOpen={modalOpen}
        handleModalClose={handleModalClose}
      />
    </Elements>
  );
};

export default SubscribeDialog;
