import { Button } from "@mui/material";

const CancelButton = ({ onClick }) => {
  return (
    <Button
      onClick={onClick}
      variant="outlined"
      sx={{
        height: 36,
        width: 70,
        borderRadius: 0,
        textTransform: "none",
        fontSize: "14px",
        fontWeight: "bold",
        color: "#0A284A",
        borderColor: "#0A284A",
        fontFamily: "Akrobat",
        ":hover": {
          borderColor: "#2C4A6C",
        },
      }}
    >
      Cancel
    </Button>
  );
};
export default CancelButton;
